.App {
  text-align: center;
  font-family: "Press Start 2P";
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 110%;
  height: 110%;
  background: url('/public/back.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: hue-rotate(0deg) blur(8px) grayscale(60%); /* Задайте размытие */

  z-index: -1; /* Отправляем фон назад */
  transform: scale(1.02);
}

.content {
  position: relative;
  z-index: 1;
}

.StickyTop{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255,255,255,0.1);
  backdrop-filter: blur(10px);
}

.Logo-holder, .Rot-img{

  text-align: center;
  width: 100px;
  padding-top: 15px;
  padding-bottom: 11px;
}

.Hr{
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(255,255,255,0.3);
}



.App-header {

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  a{
    font-size: 12px;
    height: 25px;
  }

}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stream2{
  width: 60%;
  max-height: 570px;
  margin: 0;
  display: flex;
  height: 100vh;
}
.stream-container2 {
  flex: 2;
  background: #000;
}
.chat-container2 {
  flex: 1;
  background: #f4f4f4;
  border-left: 1px solid #ddd;
}
.vk-frame {
  width: 100%;
  height: 100%;
  border: none;
}

.vk-stream {
}
