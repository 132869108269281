@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.FitContent{

}

.CardsHolder{
    display: flex;
}

.games-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбца одинаковой ширины */
    gap: 30px; /* Отступы между элементами */
    align-items: start; /* Элементы выравниваются по началу ячейки */
}


.laraItem, .laraItem0, .laraItem1{
    border: 1px solid #ccc;
    padding: 10px;

    border-radius: 10px;
    background-color: #e8e8e8;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 600px;
    max-height: 100%;
    padding: 10px;
    //margin-top: 50px;
    margin-bottom: 50px;
    font-family: "Press Start 2P";
    object-fit: cover;
    overflow: hidden;

    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

.laraItem:hover, .laraItem:focus, .laraItem:active {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.laraItem0:hover, .laraItem0:focus, .laraItem0:active {
    box-shadow: 0 0 20px #868600;
}

.laraItem1:hover, .laraItem1:focus, .laraItem1:active {
    box-shadow: 0 0 20px #047000;
}


.laraItem0{
    background-color: #fffeb0;
}

.laraItem1{
    background-color: #b3ffb0;
}
@media (max-width: 700px) {
    .laraItem, .laraItem0, .laraItem1, .FitContent {
        width: 90%;
    }
}

.backgroundimage
{

    transform: translate(0,-20%);
    text-align: center;
    z-index: -1;
    min-height: 100%;
    //min-width: 100%;
    padding: 0;
    //position: fixed;
    top: 0;
    width: 99%;
    //filter: blur(2px) ;
    float: none;
}





.laraImg{

    position: relative;

    //object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */

    width: 100%;

    height: 320px;

    overflow: hidden;

}

.MetaCritic, .MetaCriticRed, .MetaCriticGreen{
    position: absolute;
    /*top: 47%;*/
    left: 10%;
    transform: translate(-50%, -20%);

    text-align: center;

    width: 50px;
    height: 50px;
    background-color: yellow;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    filter: drop-shadow(4px 4px 2px black);
    z-index: 10;
}

.MetaCriticGreen{
    background-color: greenyellow;
}
.MetaCriticRed{
    background-color: orangered;
}

.laraDesc{
    font-weight: normal;
    font-size: 14px;
    color: #5d5d5d;
}

.hvr-glow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}
