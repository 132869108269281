.achievements-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    gap: 10px;
    padding: 20px;
}

.achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    transition: opacity 0.3s, transform 0.3s;
    max-width: 150px; /* Ограничение ширины элемента */
    margin: auto; /* Центрирование элемента */
}

.achievement.achieved {
    opacity: 1;
}

.achievement.not-achieved {
    opacity: 1;
}
.achievement-unlocktime {
    font-size: 12px;
    color: #ccc;
    margin-top: 5px;
}

.achievement-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 5px;
}

.achievement-name {
    font-size: 14px;
    word-wrap: break-word;
}

.achievement-description {
    font-size: 12px;
    color: #aaa;
    margin-top: 5px;
}

.CardsHolder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-holder
{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбца одинаковой ширины */
    gap: 30px; /* Отступы между элементами */
    align-items: start; /* Элементы выравниваются по началу ячейки */
}
.container {
    margin: 30px;
    display: flex;
    width: 900px;
    min-height: 450px;
    background-image: url("/public/Chapters/Глава 1.jpg");
    background-size: 100%;
    box-shadow: 0px 0px 10px 2px gold;
}

.left-panel {
    min-height: 450px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@font-face {
    font-family: "gothic"; /* Имя шрифта, которое вы будете использовать в CSS */
    src: url("/public/centurygothic.ttf") format("truetype"); /* Укажите путь к файлу и его формат */
}

.header {
    font-family: "gothic";
    font-size: 70px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
}

.image {
    width: 80%;
    height: auto;
    border: 2px solid gold;
}

.right-panel {
    width: 50%;
    height: 70%;
    margin-top: 7%;
    margin-bottom: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);

}

.box {
    width: 90%;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 10px auto;
    opacity: 0.8;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px gold;
}


